import dynamic from "next/dynamic";
import handleServerSideProps from "components/templates/handleServerSideProps";
import { getTemplate } from "library/helpers/configs";

const DefaultTemplate = dynamic(() =>
  import("../../components/templates/template-default-profile")
);

export default function Profile(props) {
  const {customTemplateConfig} = props;

  let Template = customTemplateConfig
    ? getTemplate(customTemplateConfig.template)
    : null;

  Template = Template || DefaultTemplate;

  return <Template {...props} />;
}

export const getServerSideProps = async (...args) => {
  try {
    const res = await handleServerSideProps(...args);

    return res;
  } catch (err) {
    console.log("Error...", err);
  }
};
